@import "./fonts/nunito/nunito.css";

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  margin: 0;
  font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code:not(.doc-highlight) {
  border-radius: 2px;
  color: #e96900;
  font-size: 0.8rem;
  margin: 0 2px;
  padding: 3px 5px;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  font-family:
    Roboto Mono,
    Monaco,
    courier,
    monospace;
}

a code {
  color: #3498ff !important;
  background: none !important;
}
pre {
  border: none;
  border-radius: 0;
}

/* Marking [hidden] as display:none provides an extra layer of clickjacking security for older browsers. */
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: 700;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
  border-radius: 0;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
textarea {
  overflow: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
[tabindex="-1"] {
  outline: none;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border-width: 0;
}
input::-ms-clear {
  display: none;
}
input[type="file"]::-ms-browse,
input[type="file"]::-webkit-file-upload-button {
  border-width: 0;
  background: transparent;
  color: currentColor;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #1675e0;
  text-decoration: none;
}
a:focus,
a:hover {
  color: #0a5dc2;
}
a:active,
a:focus,
a:hover {
  text-decoration: underline;
}
a:active {
  color: #004299;
}
a:focus {
  outline: 0;
}

img {
  vertical-align: middle;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e5e5ea;
}
small {
  font-size: 85%;
}
mark {
  background-color: #fff9e6;
  padding: 0.2em;
  font-weight: bolder;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dl {
  margin-top: 0;
  margin-bottom: 10px;
}
dd,
dt {
  line-height: 1.42857143;
}
dt {
  font-weight: 700;
}
dd {
  margin-left: 0;
  margin-bottom: 10px;
}

.red {
  background-color: #dc3545;
}
.yellow {
  background-color: #f5e042;
}
.green {
  background-color: #00c292;
}
.orange {
  background-color: #f57b51;
}

.coloredCircle {
  height: 20px;
  display: flex;
  padding: 0 4px;
  z-index: 1;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  box-sizing: border-box;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  color: white;
}

.filledCircle {
  width: 20px;
  height: 20px;
  border-radius: 21px;
  display: inline-block;
  border: 2px solid #ffffff;
}

.name-header {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 34px;
  color: #48465b;
  font-weight: 500;
  align-items: center;
}

.DateRangePicker_picker {
  z-index: 2000 !important;
}
