/* Webfont: SuisseScreen-Monitor */
@font-face {
  font-family: "SuisseScreen";
  src: url("fonts/SuisseScreen-Monitor-WebS.eot"); /* IE9 Compat Modes */
  src:
    url("fonts/SuisseScreen-Monitor-WebS.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/SuisseScreen-Monitor-WebS.woff2") format("woff2"),
    /* Modern Browsers */ url("fonts/SuisseScreen-Monitor-WebS.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/SuisseScreen-Monitor-WebS.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 375;
  text-rendering: optimizeLegibility;
}

/* Webfont: SuisseScreen-MonitorItalic */
@font-face {
  font-family: "SuisseScreen";
  src: url("fonts/SuisseScreen-MonitorItalic-WebS.eot"); /* IE9 Compat Modes */
  src:
    url("fonts/SuisseScreen-MonitorItalic-WebS.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/SuisseScreen-MonitorItalic-WebS.woff2")
      format("woff2"),
    /* Modern Browsers */ url("fonts/SuisseScreen-MonitorItalic-WebS.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/SuisseScreen-MonitorItalic-WebS.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 375;
  text-rendering: optimizeLegibility;
}

/* Webfont: SuisseScreen-Regular */
@font-face {
  font-family: "SuisseScreen";
  src: url("fonts/SuisseScreen-Regular-WebS.eot"); /* IE9 Compat Modes */
  src:
    url("fonts/SuisseScreen-Regular-WebS.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/SuisseScreen-Regular-WebS.woff2") format("woff2"),
    /* Modern Browsers */ url("fonts/SuisseScreen-Regular-WebS.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/SuisseScreen-Regular-WebS.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/* Webfont: SuisseScreen-RegularItalic */
@font-face {
  font-family: "SuisseScreen";
  src: url("fonts/SuisseScreen-RegularItalic-WebS.eot"); /* IE9 Compat Modes */
  src:
    url("fonts/SuisseScreen-RegularItalic-WebS.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/SuisseScreen-RegularItalic-WebS.woff2")
      format("woff2"),
    /* Modern Browsers */ url("fonts/SuisseScreen-RegularItalic-WebS.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/SuisseScreen-RegularItalic-WebS.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/* Webfont: SuisseScreen-Medium */
@font-face {
  font-family: "SuisseScreen";
  src: url("fonts/SuisseScreen-Medium-WebS.eot"); /* IE9 Compat Modes */
  src:
    url("fonts/SuisseScreen-Medium-WebS.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/SuisseScreen-Medium-WebS.woff2") format("woff2"),
    /* Modern Browsers */ url("fonts/SuisseScreen-Medium-WebS.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/SuisseScreen-Medium-WebS.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: SuisseScreen-MediumItalic */
@font-face {
  font-family: "SuisseScreen";
  src: url("fonts/SuisseScreen-MediumItalic-WebS.eot"); /* IE9 Compat Modes */
  src:
    url("fonts/SuisseScreen-MediumItalic-WebS.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/SuisseScreen-MediumItalic-WebS.woff2")
      format("woff2"),
    /* Modern Browsers */ url("fonts/SuisseScreen-MediumItalic-WebS.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/SuisseScreen-MediumItalic-WebS.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: SuisseScreen-SemiBold */
@font-face {
  font-family: "SuisseScreen";
  src: url("fonts/SuisseScreen-SemiBold-WebS.eot"); /* IE9 Compat Modes */
  src:
    url("fonts/SuisseScreen-SemiBold-WebS.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/SuisseScreen-SemiBold-WebS.woff2") format("woff2"),
    /* Modern Browsers */ url("fonts/SuisseScreen-SemiBold-WebS.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/SuisseScreen-SemiBold-WebS.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: SuisseScreen-SemiBoldItalic */
@font-face {
  font-family: "SuisseScreen";
  src: url("fonts/SuisseScreen-SemiBoldItalic-WebS.eot"); /* IE9 Compat Modes */
  src:
    url("fonts/SuisseScreen-SemiBoldItalic-WebS.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/SuisseScreen-SemiBoldItalic-WebS.woff2")
      format("woff2"),
    /* Modern Browsers */ url("fonts/SuisseScreen-SemiBoldItalic-WebS.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/SuisseScreen-SemiBoldItalic-WebS.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
