/* nunito-300 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/nunito-v16-latin-300.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./fonts/nunito-v16-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/nunito-v16-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/nunito-v16-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/nunito-v16-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/nunito-v16-latin-300.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/nunito-v16-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./fonts/nunito-v16-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/nunito-v16-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/nunito-v16-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/nunito-v16-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/nunito-v16-latin-regular.svg#Nunito") format("svg"); /* Legacy iOS */
}
/* nunito-700 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/nunito-v16-latin-700.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./fonts/nunito-v16-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/nunito-v16-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/nunito-v16-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/nunito-v16-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/nunito-v16-latin-700.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
